// extracted by mini-css-extract-plugin
export var outerCard = "ServicePageWrapper-module--outerCard--26ebc";
export var serviceItemWrapper = "ServicePageWrapper-module--serviceItemWrapper--3ac20";
export var serviceItemCard = "ServicePageWrapper-module--serviceItemCard--5736e";
export var serviceItem = "ServicePageWrapper-module--serviceItem--4aa70";
export var active = "ServicePageWrapper-module--active--9efc8";
export var serviceItemImage = "ServicePageWrapper-module--serviceItemImage--1dbdf";
export var serviceItemText = "ServicePageWrapper-module--serviceItemText--4a9b9";
export var hero = "ServicePageWrapper-module--hero--3a1e6";
export var heroDetails = "ServicePageWrapper-module--heroDetails--73576";
export var heroImage = "ServicePageWrapper-module--heroImage--e3e72";