import * as styles from './ServiceDetailItem.module.css';
import * as React from 'react';

import tilePhoto from '../../../../images/Work Examples/ceramic_tile.jpg';
import showerAndTubPhoto from '../../../../images/Work Examples/tub_and_shower.jpg';
import cabinetryPhoto from '../../../../images/Work Examples/cabinetry.jpg';
import doorsPhoto from '../../../../images/Work Examples/doors.jpg';
// import kitchenElectricalPhoto from '../../../../images/Work Examples/kitchen_electrical.jpg';
import railingsPhoto from '../../../../images/Work Examples/railings.jpg';
import trimPhoto from '../../../../images/Work Examples/trim.jpg';
import bathroomFlooringPhoto from '../../../../images/Work Examples/bathroom_flooring.jpg';
// import bathroomPlumbingPhoto from '../../../../images/Work Examples/bathroom_plumbing.jpg';
import demolitionPhoto from '../../../../images/Work Examples/demolition.jpg';
import paintingPhoto from '../../../../images/Work Examples/painting.jpg';
import insulationPhoto from '../../../../images/Work Examples/insulation_copy.jpg';
import framingPhoto from '../../../../images/Work Examples/framing.jpg';
import flooringPhoto from '../../../../images/Work Examples/flooring.jpg';

import { useState } from 'react';

const ServiceDetailItem = (props) => {
    const services = {
        ceramicTile: {
            title: 'Ceramic Tile',
            description: "I can't count the times I have been asked to examine a bad tile job: a client often asks me why their grout is cracking only to see me easily lift the tile with my fingers. Cutting and shaping the hard, porcelain tiles that are the industry standard is a skilled job in itself, but making the tiles fit is only half the job at best. Proper ceramic installations should age with grace and not require replacement until they simply go out of style; however, the only way to achieve these results is to understand that tile work is an intersection of artistry and chemistry. Every job, from large format tiles in a custom shower to intricate mosaics on a kitchen backsplash, requires a different set of tools, skills and materials. I pride myself in knowing what each unique situation requires.",
            imageSource: tilePhoto,
            imageAlt: 'ceramic tile'
        },
        showerAndTub: {
            title: 'Shower and Tub Installation',
            description: 'Tubs and showers are the heart of any bathroom. Options today are seemingly limitless in this arena, so what you need is a trusted advisor to help you choose products that fit your style and budget. Once you have made your decision, quality of installation is paramount. I stay up to date with the latest techniques for waterproofing because flat wall tiles and even grout lines are important, but water management is essential. Regardless whether I install a simple, prefabricated unit or a complicated, custom tile shower, one thing remains the same: I can guarantee water will remain where it belongs.',
            imageSource: showerAndTubPhoto,
            imageAlt: 'shower and tub'
        },
        painting: {
            title: 'Painting',
            description: "I started out my career as a painter. Initially, while apprenticing under my first boss, the only tool he let me touch was a paint brush. Today, I don't typically accept jobs that only involve painting, but I haven't forgotten how to apply a glass smooth finish. I do all the painting my projects require, and it remains one of my stronger trades.",
            imageSource: paintingPhoto,
            imageAlt: 'painting'
        },
        // plumbing: {
        //     title: 'Plumbing',
        //     description: ' In my own house there is no plumbing job I won’t attempt. Professionally, I am a little less cavalier, but for most jobs my comfort zone is more than enough. Most importantly, I know when it is time to roll up my sleeves and when it is time to call a plumber.',
        //     imageSource: tilePhoto,
        //     imageAlt: 'plumbing'
        // },
        // bathroomPlumbing: {
        //     title: 'Plumbing',
        //     description: ' In my own house there is no plumbing job I won’t attempt. Professionally, I am a little less cavalier, but for most jobs my comfort zone is more than enough. Most importantly, I know when it is time to roll up my sleeves and when it is time to call a plumber.',
        //     imageSource: bathroomPlumbingPhoto,
        //     imageAlt: 'plumbing'
        // },
        flooring: {
            title: 'Flooring',
            description: 'For the most part, I take care of all the flooring on my jobs. I am skilled in ceramic, LVT, laminate and hardwood flooring. I know that preparation is the key to a lasting flooring installation, so I always make sure to strictly adhere to the manufacture’s specifications. This may seem commonplace, but the simple mistakes I frequently see suggest otherwise.',
            imageSource: flooringPhoto,
            imageAlt: 'flooring'
        },
        bathroomFlooring: {
            title: 'Flooring',
            description: 'For the most part, I take care of all the flooring on my jobs. I am skilled in ceramic, LVT, laminate and hardwood flooring. I know that preparation is the key to a lasting flooring installation, so I always make sure to strictly adhere to the manufacture’s specifications. This may seem commonplace, but the simple mistakes I frequently see suggest otherwise.',
            imageSource: bathroomFlooringPhoto,
            imageAlt: 'flooring'
        },
        demolition: {
            title: 'Demolition',
            description: 'Demolition may seem like a brainless task. Television would have you believe that all one needs is a sledgehammer and some well-placed aggression. I prefer a more surgical approach. Keeping dust to a minimum (though the use of HEPA filtration when warranted or requested) is my number one priority. I rarely see the need to “go to the studs”; certainly, there are times that such scope is necessary, but often it does nothing but produce more waste and mess.',
            imageSource: demolitionPhoto,
            imageAlt: 'demolition'
        },
        // electrical: {
        //     title: 'Electrical',
        //     description: 'In my own house there is no electric job I won’t attempt. Professionally, I am a little less cavalier, but for most jobs my comfort zone is more than enough. Most importantly, I know when it is time to roll up my sleeves and when it is time to call an electrician.',
        //     imageSource: tilePhoto,
        //     imageAlt: 'electrical'
        // },
        // kitchenElectrical: {
        //     title: 'Electrical',
        //     description: 'In my own house there is no electric job I won’t attempt. Professionally, I am a little less cavalier, but for most jobs my comfort zone is more than enough. Most importantly, I know when it is time to roll up my sleeves and when it is time to call an electrician.',
        //     imageSource: kitchenElectricalPhoto,
        //     imageAlt: 'electrical'
        // },
        cabinetry: {
            title: 'Cabinetry',
            description: 'Installing kitchen cabinets is one of my favorite things to do. I love the required precision and the high stakes of working with finished products. I live for the challenge of fitting perfect boxes into the imperfection of real construction. Even fine tuning the hinges to produce even gaps and perfectly aligned handles gives me a warm sense of satisfaction. Lasers have made the cabinet installer’s job easier than it once was, but the prevalence of natural stone as countertops (and with it a demand for flatness) has made perfection a requirement. Cabinets are easily the single biggest investment in a kitchen, and I treat them with the dignity they deserve.',
            imageSource: cabinetryPhoto,
            imageAlt: 'cabinetry'
        },
        insulation: {
            title: 'Insulation',
            description: "I can't count the number of times I have seen faced fiberglass insulation installed in basement walls. The problem is, unlike above ground assemblies, basement walls have the potential to take on water from both sides. In modern houses build to high standards, foundations are protected with exterior insulation, and in this case the risk of water entering from the outside is low. This insulation also means condensation on the interior is also less likely. However, this building practice is relatively new, and many of the basements I am asked to finish do not have this important detail. In that case, water management is key; I won't finish a basement that habitually has visible water intrusion. Once the space is dry there are several options (each with their own costs and benefits). It costs a little more to do it right, but it sure beats growing a mold farm.",
            imageSource: insulationPhoto,
            imageAlt: 'insulation'
        },
        framing: {
            title: 'Framing',
            description: 'Framing is an important aspect of basement finishing. The wood (or metal) frame is the skeleton that makes for strong, straight walls. No amount of drywall finishing, painting or trim work will make up for crooked framing. Rough framing may not have the sex appeal of finish trades, and it is unlikely the layman will know a bad wall at this stage of construction, but anyone can see the effects on this finished product.',
            imageSource: framingPhoto,
            imageAlt: 'framing'
        },
        trim: {
            title: 'Trim',
            description: 'Trim is a rather broad category. I would group everything from simple baseboard molding to custom wainscoting within this diverse trade. Essentially, the job of the trim carpenter is to cover up the mistakes made by all the other trades, but the sum can be more than the parts. For instance, baseboards are designed to cover the necessary gap left by flooring and drywall, but they also add a sense of continuity to a house, and they make you feel grounded by providing a level line. Wainscoting was made to protect walls from damage, but it can make an otherwise lifeless two-story foyer a work of art. Attention to detail and creativity are key to a good trim carpenter, and I try to bring my best of both to every trim job.',
            imageSource: trimPhoto,
            imageAlt: 'trim'
        },
        doors: {
            title: 'Doors',
            description: 'Well hung doors can be heard as easily as they can be seen; the sound of a door latching effortlessly is unmistakable. Given that they move, they function through several planes and they occupy holes that are rarely square, doors are tricky to install perfectly and often fail. That’s why I pride myself on my ability both to install new doors as well as to repair existing doors. My dad likes to call me the “door doctor”, but that’s not necessarily a name I answer to.',
            imageSource: doorsPhoto,
            imageAlt: 'doors'
        },
        railings: {
            title: 'Railings',
            description: 'Balustrades and handrails are particularly challenging. Not only must they be made with precision to function safely, but they also must also be built to be incredibly robust. To be fair, anything dealing with stairs is subject to more building code than most other parts of a house, but I can think of nothing else that requires a combination of strength and beauty as much as a balustrade. I’ve constructed all sorts of railings— from traditional wooden structures to more modern combinations of iron and wood. I’ve even tried my hand with some cable railing (although I consider that carpentry adjacent). Regardless of the style, my balustrades are always code compliant as well as stunning to look at.',
            imageSource: railingsPhoto,
            imageAlt: 'railings'
        }

    }

    const [show, setShow] = useState(false);

    const service = services[props.service];

    const detailsClasses = [styles.mobileDescription];
    const flexClasses = [styles.detailsFlexRow];
    const toggleClasses = [styles.toggleButton]

    if (show) {
        detailsClasses.push(styles.showDetails);
        flexClasses.push(styles.transformFlexbox);
        toggleClasses.push(styles.toggleOpen);
    }

    const toggleShow = () => {
        setShow((prevShow) => !prevShow)
    }

    return (
        <div className={styles.serviceDetailsItem}>
            <div className={flexClasses.join(' ')} onClick={toggleShow}>
                <div className={styles.serviceDetailsImageBox}>
                    <img src={service.imageSource} alt={service.imageAlt} />
                </div>
                <div className={styles.serviceDetailsDescriptionBox}>
                    <h3 >{service.title}</h3>
                    <div className={toggleClasses.join(' ')}>
                        <div></div>
                        <div></div>
                    </div>
                    <p>{service.description}</p>
                </div>
            </div>
            <div className={detailsClasses.join(' ')}>
                <p onClick={toggleShow}>{service.description}</p>
            </div>
        </div>
    )
}

export default ServiceDetailItem;