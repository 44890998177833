
import * as React from 'react';
import * as styles from './Image.module.css';

import { useEffect, useState } from 'react';

const Image = (props)=>{
    const [imageStyles, setImageStyles] = useState([props.className]);
    useEffect(()=>{
        setImageStyles((prevStyles)=>{
            return [...prevStyles, styles.show]
        })
    }, [])

    return <img className={imageStyles.join(' ')} src={props.src} alt={props.alt} />
}

export default Image;