import * as styles from '../../page-styles/ServiceCategory.module.css';
import * as React from 'react';

import bathroomHero from '../../images/Work Examples/20190329_090628.jpg';
import kitchenHero from '../../images/Work Examples/kitchen.jpg';
import basementHero from '../../images/Work Examples/basement.jpg';
import carpentryHero from '../../images/Work Examples/carpentry.jpg';

import ServicePageWrapper from '../../components/services/ServicePageWrapper/ServicePageWrapper';
import Image from '../../components/ui/Image/Image';

import ServiceDetailItem from '../../components/services/Service/ServiceDetailItem/ServiceDetailItem';

import { useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import Seo from '../../components/seo'; 

const images = {
    bathroomHero: bathroomHero, 
    kitchenHero: kitchenHero,
    basementHero: basementHero,
    carpentryHero: carpentryHero
}


const ServiceCategory = ({data, location}) => {
    let internalServiceLink = false;
    if(location.state){
        internalServiceLink = location.state.internalServiceLink;
    }

    useEffect(()=>{
        if(typeof window !== 'undefined'){
            if(internalServiceLink){     
                    return;
            }  
        window.scrollTo(0,0)
        }  
}, [internalServiceLink])

   if(data.mdx.frontmatter.servicepage){
        return (
      
            <ServicePageWrapper location={data.mdx.slug}>
                <Seo 
                title={data.mdx.frontmatter.title} 
                description={data.mdx.frontmatter.pageDescription}
                />

        <div>
            <div className={styles.hero}>
                {data.mdx.frontmatter.imageInvert === 'true'
                    ? <Image src={images[data.mdx.frontmatter.imageSource]} alt={data.mdx.frontmatter.imageAlt} className={styles.heroImage} />
                    : null}
                <div className={styles.heroDetails}>
                    <h2>{data.mdx.frontmatter.title}</h2>
                    <img src={images[data.mdx.frontmatter.imageSource]} alt={data.mdx.frontmatter.imageAlt} className={styles.mobileHeroImage} />
                    <p>{data.mdx.frontmatter.description}</p>
                   
                </div>
                {data.mdx.frontmatter.imageInvert === 'false'
                    ? <Image src={images[data.mdx.frontmatter.imageSource]} alt={data.mdx.frontmatter.imageAlt} className={styles.heroImage} />
                    : null}
            </div>

            <div className={styles.serviceDetails}>

                <h2 className={styles.subHeader}>{data.mdx.frontmatter.subHeader}</h2>

                    {data.mdx.exports.services.map((service)=>{
                        return  <ServiceDetailItem  key={`${service} details`} service={service}/>
                    })}
               
                <Link className={styles.bookingButton} to={'/work-request'}>Book a job today!</Link>

            </div>
        </div>
        </ServicePageWrapper>
    )

    } else {
        
        return null;
    }

}
export const query = graphql`
query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
       title
        subHeader
        description
        imageSource
        imageAlt
        imageInvert
        servicepage
        pageDescription
      }
      slug
        exports{
            services
        }
    }
}
`

export default ServiceCategory;