
import * as styles from './ServicePageWrapper.module.css';
import * as React from 'react';

import BathroomIcon from '../../../images/white_bathroom_icon.png'
import carpentryIcon from '../../../images/general-repair-icon-white.png';
import BasementIcon from '../../../images/basement-icon-white.png';
import KitchenIcon from '../../../images/kitchen-icon-white.png';

import { Link } from 'gatsby';

import Layout from '../../Layout/Layout';

const Services = ({ children, location }) => {
    return (
        <Layout location='services'>
            <main>
                <div className={styles.outerCard}>
                    <div className={styles.serviceItemCard}>

                        <div className={styles.serviceItemWrapper}>
                            <Link
                                to='/services/bathroom'
                                state={{internalServiceLink: true}}
                                className={
                                    [styles.serviceItem, location === 'bathroom' ?
                                        styles.active :
                                        ''].join(' ')
                                }>

                                <img className={styles.serviceItemImage} src={BathroomIcon} alt="bathroom" />
                                <p className={styles.serviceItemText}>Bathroom</p>

                            </Link>
                            <Link
                                to='/services/kitchen'
                                state={{internalServiceLink: true}}
                                className={
                                    [styles.serviceItem, location === 'kitchen' ?
                                        styles.active :
                                        ''].join(' ')
                                }>

                                <img className={styles.serviceItemImage} src={KitchenIcon} alt="kitchen" />
                                <p className={styles.serviceItemText}>Kitchen</p>
                            </Link>
                            <Link
                                to='/services/basement'
                                state={{internalServiceLink: true}}
                                className={
                                    [styles.serviceItem, location === 'basement' ?
                                        styles.active :
                                        ''].join(' ')
                                }>

                                <img className={styles.serviceItemImage} src={BasementIcon} alt="basement" />
                                <p className={styles.serviceItemText}>Basement</p>

                            </Link>
                            <Link
                                to='/services/carpentry'
                                state={{internalServiceLink: true}}
                                className={
                                    [styles.serviceItem, location === 'carpentry' ?
                                        styles.active :
                                        ''].join(' ')
                                }>

                                <img className={styles.serviceItemImage} src={carpentryIcon} alt="carpentry" />
                                <p className={styles.serviceItemText}>Carpentry</p>

                            </Link>
                        </div>
                    </div>

                    {children}

                </div>
            </main>
        </Layout>
    )
}


export default Services;